export const isCoreExtra = {
  'aca-plans': true,
  'alinta': true,
  'autoshipping': true,
  'boost': true,
  'club-colors': true,
  'easy': true,
  'ihealth-quotes': true,
  'oca': true,
  'open-colleges': true,
  'rac': true,
  'rac-finance': true,
  'rac-rsa': true,
  'rac-finance-carloan': true,
  'regis': true,
  'rmit-online': true,
};
